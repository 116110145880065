import numeral from 'numeral';
import { isEmpty } from '../lib/lodash';

const f = () => false;
const dataUrlRegex = /^data:((?:\w+\/(?:(?!;).)+)?)((?:;[\w\W]*?[^;])*),(.+)$/;

export const isFile = (v, cb = f) => v instanceof File || cb('非檔案');
export const required = (v, cb = f) => (!isEmpty(v) || (isFile(v) && !!v)) || cb('此項為必填');
export const min = (n) => ((v, cb = f) => v.length >= n || cb(`不可少於${n}個字`));
export const max = (n) => ((v, cb = f) => v.length <= n || cb(`不可超過${n}個字`));
export const maxFileSize = (n) => (v, cb = f) => typeof v !== 'object' || v.size <= numeral(n).value() || cb(`檔案大小不可超過${n}`);
export const maxItem = (n) => (v, cb = f) => v.length <= n || cb(`不可超過${n}項`);
export const isBase64 = (v, cb = f) => dataUrlRegex.test(v) || cb('此項非base64圖片');
export const checkMail = (v, cb = f) => (!v || /^[A-Za-z0-9.\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(v)) || cb('請輸入正確的電子信箱');
export const checkPhone = (v, cb = f) => (!v || /^09[0-9]{2}[-]?[0-9]{3}[-]?[0-9]{3}$/im.test(v)) || cb('請輸入正確的電話');
export const checkUrl = (v, cb = f) => (!v || /^(https?:\/\/)((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\\#[-a-z\d_]*)?$/i.test(v)) || cb('請輸入正確的網址');

export const realName = (v, cb = f) => /^[\u4e00-\u9fa5]{2,8}$/.test(v) || cb('請輸入真實姓名');
export const realAge = (v, cb = f) => ((Number(v) > 0) && v < 150) || cb('請輸入真實年齡');
