<template>
  <div
    role="img"
    class="dg-img relative overflow-hidden"
    v-bind="$attrs"
    :style="style"
  >
    <div :style="ratioStyle"></div>
    <div
      class="dg-img__image absolute inset-0"
      :style="imgContainerStyle"
    ></div>
  </div>
</template>

<script>
import { isEqual } from '@/lib/lodash';
import { isBase64 } from '@/utils/validator';

const platform = window.innerWidth > 768 ? 'pc' : 'mobile';
const requireComponents = require.context('../../assets/images/', true, /.[jpg|png]$/);
const imageList = requireComponents.keys().map((path) => (path.replace(/^\.\/(.+)/, '$1')));

export default {
  name: 'DgImg',
  props: {
    src: {
      type: String,
      default: '',
      required: true,
    },
    ratio: {
      type: [String, Number],
      ratio: '',
    },
    contain: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: '50% 50%',
    },
    imgStyle: {
      type: Object,
      default: () => {},
    },
    placeholderSrc: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentSrc: '',
      naturalRatio: 0,
      destroyed: false,
    };
  },
  computed: {
    isRwd() {
      const regex = new RegExp(this.currentSrc);
      const target = imageList.find((item) => item?.match(regex));
      return !/general/.test(target);
    },
    imgType() {
      const regex = new RegExp(this.currentSrc);
      const target = imageList.find((item) => item?.match(regex))?.replace(/(.+)\.(jpg|png)/, '$2');
      return imageList && this.currentSrc && target ? target : '';
    },
    device() {
      return this.isRwd ? platform : 'general';
    },
    density1x() {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return (this.currentSrc) && this.imgType ? require(`/src/assets/images/${this.device}/${this.currentSrc}.${this.imgType}`) : '';
    },
    density2x() {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return (this.currentSrc) && this.imgType ? require(`/src/assets/images/${this.device}/${this.currentSrc}@2x.${this.imgType}`) : '';
    },
    density3x() {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return (this.currentSrc) && this.imgType ? require(`/src/assets/images/${this.device}/${this.currentSrc}@3x.${this.imgType}`) : '';
    },
    srcset() {
      if (this.density1x) return `${this.density1x} 1x`;
      if (this.density2x) return `${this.density1x} 1x, ${this.density2x} 2x,`;
      if (this.density3x) return `${this.density1x} 1x, ${this.density2x} 2x, ${this.density3x} 3x`;
      return '';
    },
    url() {
      return this.imgType ? this.density1x : (this.src || this.placeholderSrc);
    },
    style() {
      return {
        width: this.width,
        height: this.height,
      };
    },
    ratioStyle() {
      const ratio = this.ratio || this.naturalRatio;
      return { paddingBottom: `${100 / ratio}%` };
    },
    imgContainerStyle() {
      return {
        backgroundSize: this.contain === true ? 'contain' : 'cover',
        backgroundPosition: this.position,
        ...this.imgStyle,
        backgroundImage: `url("${this.url}")`,
        backgroundRepeat: 'no-repeat',
      };
    },
  },
  watch: {
    src(newVal, oldVal) {
      if (!isEqual(newVal, oldVal) && newVal) {
        this.methodLoad();
      }
    },
  },
  beforeMount() {
    if (this.placeholderSrc) {
      const img = new Image();
      this.currentSrc = this.placeholderSrc;
      img.src = this.density1x || this.placeholderSrc;
      img.onload = () => {
        this.methodComputeRatio(img);
      };
      img.onerror = () => {
        console.log('err');
      };
    }
    this.methodLoad();
  },
  methods: {
    methodComputeRatio(img) {
      const { naturalHeight, naturalWidth } = img;

      this.naturalRatio = naturalHeight === 0
        ? 1
        : naturalWidth / naturalHeight;
    },
    methodLoad() {
      if (!this.src) {
        return;
      }

      const img = new Image();
      if (!isBase64(this.src)) this.currentSrc = this.src || this.placeholderSrc;

      img.onload = () => {
        if (this.destroyed === true) {
          return;
        }

        // if we are still rendering same image
        if (img.decode !== undefined) {
          img
            .decode()
            // .catch((err) => {
            // })
            .then(() => {
              if (this.destroyed !== true) {
                this.methodOnLoad(img);
              }
            });
        } else {
          this.methodOnLoad(img);
        }
      };

      img.src = this.density1x || this.src;

      if (this.srcset) {
        img.srcset = this.srcset;
      }

      if (this.sizes !== undefined) {
        img.sizes = this.sizes;
      } else {
        Object.assign(img, {
          height: this.height,
          width: this.width,
        });
      }
    },
    methodOnLoad(img) {
      this.methodComputeRatio(img);
    },
  },
  beforeDestroy() {
    this.destroyed = true;
  },
};
</script>
